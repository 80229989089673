export const cookies = {
  title: "We only use functional cookies!",
  close: "close",
  data_protection_icon: "Data Protection",
  list: {
    item_1: "No tracking cookies",
    item_2: "No Google Analytics",
    item_3: "No Facebook Pixel",
  },
  description: {
    text: "To make our offer as user-friendly and effective as possible for you, we use the {open_source} analysis tool PostHog, which we use {no_tracking} and host on {european_servers}. This way, we get summaries of the usage of individual features. Your personal data is never shared with advertising partners, let alone sold. Learn more about the particularly high data protection at LexMea {data_privacy}.",
    open_source: "open-source",
    no_tracking: "without tracking cookies",
    european_servers: "European servers",
    data_privacy: "here",
  },
};
