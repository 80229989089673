export const authentication = {
  login: "Anmelden",
  logout: "Abmelden",
  welcome: "Willkommen!",
  not_logged_in_personalize:
    "Du bist gerade nicht eingeloggt. Um deine Personalisierungen dauerhaft zu speichern jetzt:",
  limit_reached_editing:
    "Du hast das Limit der Testfunktionen ohne Account erreicht. Um die Bearbeitungsfunktionen unbegrenzt nutzen zu können jetzt:",
  limit_reached: "Du hast das Limit der Testfunktionen ohne Account erreicht.",
  username: "Benutzername",
  email: "E-Mail-Adresse",
  loginButton: "Anmelden",
  toRegister: "Zur @:authentication.registration",
  toLogin: "Zur Anmeldung",
  logged_in_successfully: "Du hast dich erfolgreich angemeldet.",
  logged_out_successfully: "Du hast dich erfolgreich abgemeldet.",
  register: "Registrieren",
  completeRegistration: "Registrierung abschließen",
  register_free_now: "Jetzt kostenlos registrieren",
  registration: {
    word: "Registrierung",
    register_free: "Kostenlos registrieren",
    confirmEmail:
      "Wir haben dir eine E-Mail mit einem Verifizierungscode an {email} gesendet. Bitte schaue auch in deinem Spam-Ordner nach.",
    codeIsValidFor: "Der Code ist für eine Stunde gültig.",
    resendCode: "Code erneut senden",
    chooseUsername: "Wähle deinen Benutzernamen",
    continue: "Weiter",
    back: "Zurück",
    almostDone: "Fast fertig!",
    complete: "Abschließen",
    registerFreeNow: "Jetzt {freeNow}",
    freeNow: "kostenfrei registrieren",
    additionalForm: {
      description: "Hilf uns, LexMea besser an deine Bedürfnisse anzupassen.",
      occupation: "Tätitgkeit",
      occupationItems: {
        noSelection: "- Keine Angabe -",
      },
      city: "Stadt",
      university: "Universität",
      university_optional: "Universität (optional)",
      studies: "Studium",
      job: "Berufstätigkeit",
    },
  },
  verification: {
    verifying: "Deine E-Mail-Adresse wird verifiziert...",
    resend: "Verifizierungsmail erneut schicken?",
    success:
      "Deine E-Mail Adresse wurde erfolgreich verifiziert. Du bist nun eingeloggt und wirst nun automatisch zum Bücherregal weitergeleitet.",
  },
  input: {
    forgotPassword: "Passwort vergessen?",
    rememberMe: "Eingeloggt bleiben",
    emailorUsername: "E-Mail-Adresse oder Benutzername",
    password: "Passwort",
    passwordRepeat: "Passwort wiederholen",
    passwordRegex:
      "Verwende 9 oder mehr Zeichen mit einer Mischung aus Buchstaben, Ziffern und Symbolen.",
    acceptTerms: "Ich stimme den {terms} zu.",
    terms: "Nutzungsbedingungen",
    contactMe:
      "Ich möchte über Neuigkeiten per E-Mail informiert werden (jederzeit widerrufbar, z.B. über Link am Ende jeder Mail).",
  },
  privacy: {
    registerTitle: "Extra starker Datenschutz",
    loginDescription:
      "Wir wenden höchste Datenschutzstandards an und verarbeiten deine Daten auf europäischen Servern. Daher ist auch ein Login mit Google oder Facebook leider nicht möglich. Erfahre {more} mehr darüber.",
    more: "hier",
    singularPoints: [
      "Wir geben deine Daten nie an Werbetreibende weiter.",
      "Wir nutzen kein Google Analytics, kein Facebook Pixel.",
      "Wir hosten auf unseren europäischen Servern.",
    ],
    discoverMore: "Erfahre mehr dazu in unseren {privacyNotes}.",
    privacyNotes: "Datenschutzhinweisen",
  },
  errors: {
    usernameRequired: "Benutzername ist erforderlich",
    passwordRequired: "Passwort ist erforderlich",
    invalidCredentials: "Ungültige Anmeldeinformationen",
    termsNeedToBeAccepted: "Die Nutzungsbedingungen müssen akzeptiert werden.",
    password_has_to_be_filled: "Passwort muss ausgefüllt werden.",
    password_needs_letters: "Passwort muss Buchstaben enthalten.",
    password_confirm_has_to_be_filled:
      "Passwortbestätigung muss ausgefüllt werden.",
    terms_and_conditions_has_to_be_accepted:
      "Die Nutzungsbedingungen müssen akzeptiert werden.",
    email_has_to_be_filled: "E-Mail-Adresse muss ausgefüllt werden.",
    email_invalid: "E-Mail-Adresse ist ungültig.",
    verification:
      "Beim Verifizieren deiner E-Mail Adresse ist ein Fehler aufgetreten.",
  },
};
