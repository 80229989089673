export const bookshelf = {
  login: "logge dich ein",
  close: "Schließen",
  register: "registriere dich",
  login_or_register: "{login} bzw. {register}",
  to_see_your_own_bookmarks: ", um deine eigenen Lesezeichen zu sehen",
  toast: {
    law_book_already_in_quick_access:
      "Das Gesetzbuch ist bereits im Schnellzugriff",
  },
  tooltips: {
    no_color: "Keine Farbe",
    remove_from_quick_access: "Aus Schnellzugriff entfernen",
    title: "Die Bibliothek ist deine zentrale Übersichtsseite.",
    quick_access:
      "Lege hier die Gesetzbücher ab, die du besonders oft verwendest.",
    bookmarks:
      "Hier werden deine im Arbeitsbereich mit Lesezeichen versehenen Normen zusammengetragen.",
    law_books:
      "Hier sind alle auf LexMea derzeit verfügbaren Gesetze aufgelistet. Wir erweitern stetig.",
    content_by_legal_field:
      "Wir haben die Normen auf LexMea Rechtsgebieten zugewiesen. Hier werden die von dir (und uns) angelegten Inhalte daher automatisch nach Rechtsgebieten sortiert angezeigt.",
  },
  tabs: {
    laws: "Gesetze",
    content: "Inhalte",
    chronic: "Chronik",
  },
  laws: {
    filter_by_title: "Nach Titel filtern",
    keywords: {
      laws: "Gesetze",
      laws_az: "Gesetze (A-Z)",
      quick_access: "Schnellzugriff",
      bookmark: "Lesezeichen",
      bookmarks: "Lesezeichen",
      law_books: "Gesetzbücher",
      contents_by_old_norms: "Inhalte nach alten Normfassungen",
      content_by_legal_field: "Inhalte nach Rechtsgebieten",
    },
    lawTypes: {
      alle: "Alle",
      de: "Bundesrecht",
      eu: "Europarecht",
      state: "Landesrecht",
      int: "Internationales Recht",
    },
    info_logged_in: {
      no_bookmarks_yet: "Du hast noch keine Lesezeichen angelegt.",
      no_contents_to_field_of_law:
        "Du hast noch keine Inhalte zu diesem Rechtsgebiet angelegt.",
      please_select_other_filters:
        "Bitte wähle andere Filtereinstellungen, um hier Inhalte anzuzeigen.",
      explanation:
        "Du kannst in deinem {quick_access} besonders häufig verwendete Gesetzbücher ablegen und farblich personalisieren.",
      bookmarks_info:
        "Darunter werden alle im Arbeitsbereich mit einem {bookmark} versehenen Normen zusammengetragen.",
      third_overview:
        "Die dritte Übersicht zeigt dir alle auf LexMea verfügbaren {laws_az}.",
    },
    info: {
      log_in_to_view_sorted_contents:
        "Melde dich an, um hier eine nach Rechtsgebieten sortierte Übersicht der angelegten Inhalte einzusehen.",
      more_info:
        "Weiter unten findest du hier bereits eine Übersicht über die auf LexMea verfügbaren {laws_az}.",
      explanation:
        "Um in deinem {quick_access} besonders häufig verwendete Gesetzbücher abzulegen und farblich zu personalisieren sowie eine Übersicht über deine im Arbeitsbereich mit einem {bookmark} versehenen Normen einzusehen, ",
    },
  },
  content: {
    keywords: {
      by_legal_field: "nach Rechtsgebieten",
      filter_icon: "Filter-Icon",
      lexmea_schemata: "LexMea-Schemata",
      own_schemata: "Eigene Schemata",
      notes: "Notizen",
      references: "Verweise",
      markings: "Markierungen",
      underlines: "Unterstreichungen",
      bookmarks: "Lesezeichen",
    },
    info: {
      intro:
        "Wir haben alle Normen auf LexMea Rechtsgebieten zugewiesen. Im Arbeitsbereich angelegte {contents} (Schemata, Notizen etc.) werden hier daher {by_legal_field} sortiert angezeigt.",
      logged_in:
        "Nutze das {filter_icon}, um einzustellen, welche Art von Inhalten dir angezeigt werden soll.",
      no_user: {
        part_2: "Du siehst bereits {all_schemata}.",
        all_schemata: "alle von LexMea redaktionell erstellten Schemata",
        part_3:
          "Um deine derzeit lediglich lokal gespeicherten eigenen Einträge angezeigt zu bekommen,",
      },
    },
  },
  chronic: {
    table: {
      type: "Typ",
      norm: "Norm",
      name_or_section: "Name / Textstelle",
      field_of_law: "Rechtsgebiet",
      recover: "Wiederherstellen",
      deleted_at: "Gelöscht am",
      updated_at: "Angelegt / Bearbeitet am",
    },
    restore: {
      element_restored: "Element wiederhergestellt",
      abort: "Abbrechen",
      change_to_norm: "Zu Norm wechseln",
      successfully_restored:
        "{restoredType} wurde erfolgreich wieder hergestellt. Möchtest du jetzt zu {lastRestoredEntry} wechseln?",
    },
    errors: {
      marking_recover_failed:
        "Markierung konnte nicht wiederhergestellt werden.",
    },
    info: {
      logged_in:
        "Im Arbeitsbereich an Normen angelegte Inhalte (Schemata, Markierungen etc.) werden hier nach Bearbeitungsdatum sortiert angezeigt. Gelöschte Inhalte können binnen 30 Tagen vom Papierkorb aus wiederhergestellt werden.",
      no_user:
        "{action}, um hier deine im Arbeitsbereich an Normen angelegten Inhalte (Schemata, Markierungen etc.) nach Bearbeitungsdatum sortiert angezeigt zu bekommen und gelöschte Inhalte binnen 30 Tagen vom Papierkorb aus wiederherstellen zu können.",
    },
    current: {
      heading: "Inhalte nach Erstellungsdatum",
      subtitle:
        "Hier findest du eine chronologisch sortierte Auflistung der von dir erzeugten Inhalte.",
      empty_all: "Du hast noch nichts angelegt.",
      empty_type: "Du hast noch keine {type} angelegt.",
      no_user:
        "{action}, um hier eine Chronik aller von dir angelegten Inhalte einzusehen.",
    },
    deleted: {
      heading: "Papierkorb",
      subtitle:
        "Hier findest du eine chronologisch sortierte Auflistung der von dir gelöschten Inhalte.",
      empty_all: "Du hast noch keine gelöschten Inhalte.",
      empty_type: "Du hast noch keine gelöschten {type}.",
      no_user: "{action}, um hier deine gelöschten Inhalte einzusehen.",
    },
  },
};
