export const intro_page = {
  hero_section: {
    main_title: "Your customizable\nonline law book.",
    beta_label: "BETA",
    slogan: "Law. Simple. Digital.",
    use_for_free: "Use for free!",
    preview_element: {
      law_side: {
        title: "Law.",
        description:
          "{emphasized} you will find the legal norms and personalize them",
        emphasized: "On the left",
        features: {
          marking: "Marking",
          linking: "Linking",
          bookmarking: "Bookmarking",
          more: "and more.",
        },
      },
      knowledge_side: {
        title: "Knowledge.",
        description:
          "{emphasized} you will find the corresponding knowledge in the form of",
        emphasized: "On the right",
        features: {
          notes: "Notes",
          own_schemata: "Own Schemas",
          lexmea_schemata: "LexMea Schemas",
          more: "and more.",
        },
      },
    },
  },
  features_section: {
    title: "Features",
    description: "Personalize your law book",
    first_feature: {
      title: "Underline, Highlight & Link",
      description: "See at a glance where something important is in the norms!",
    },
    second_feature: {
      title: "Create Notes and Definitions",
      description:
        "Make your notes directly on the law: annotations, definitions, and everything you want to remember quickly!",
    },
    third_feature: {
      title: "View Schemas or Create Your Own",
      description:
        "View LexMea schemas for all important norms or create your own!",
    },
  },
  advantages_section: {
    title: "Advantages",
    description: "Utilize the benefits of digitalization",
    first_feature: {
      title: "Link Your Knowledge",
      description: "through permanent storage at the appropriate norm.",
    },
    second_feature: {
      title: "Find Everything",
      description:
        "thanks to digital storage and central search for laws, schemas, and notes",
    },
    third_feature: {
      title: "Study Sustainably",
      description: "and avoid ⌀ 35,000 pages* for analog laws and updates",
    },
  },
  testimonials_section: {
    title: "Opinions.",
    description: "What others say about LexMea",
    wischmeyer_testimonial: {
      picture_attribution: "Photo by Marzena Skubatz",
      text: "Central to a good exam solution is the linking of norm and knowledge. LexMea helps with that.",
      author: "Prof. Dr. Thomas Wischmeyer",
      position: "Professor of Public Law and Law of Digitalization",
    },
  },
  data_ownership_section: {
    title: "Data Sovereignty",
    description: "We do not make money with your data.",
    data_ownership:
      "We never sell any {data_ownership_link}! They remain secure - on European servers.",
    data_ownership_link: "data",
    free_features:
      "All basic functions of LexMea are free for you at any time {free_features_emphasized}",
    free_features_emphasized: "free.",
    financing:
      "LexMea will be funded in the long term through legal job advertisements and tutorials.",
  },
};
