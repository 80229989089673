// de -> transform to Datenschutz
export const data_protection = {
  title: "Datenschutzhinweise",
  subtitle: "DS-GVO und mehr!",
  intro_container: {
    title: "Das Wichtigste in Kürze",
    first_paragraph:
      "Auch wenn du es nicht sehen kannst: Bei LexMea gehört die {trustworthy_policy}, die bei deiner Nutzung unserer Services entstehen, genauso zum Service wie ein hochwertiges Online-Angebot selbst.",
    trustworthy_policy:
      "sichere, vertrauens- und respektvolle Verarbeitung der Daten",
    second_paragraph:
      "Um die Sicherheit deiner Daten angemessen bei der Übertragung zu schützen, verwenden wir orientiert am {current_technology} (z.B. DDoS-Schutz, SQL Injection-Protection, Anti-CSRF-Token…).",
    current_technology:
      "aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z.B. SSL/TLS) und gesicherte technische Systeme",
    third_paragraph:
      "Um den Schutz deiner personenbezogenen Daten zu garantieren, gehen wir zudem an zahlreichen Stellen über {high_requirements}. Du findest diese Stellen durch unser LexMea-Datenschutzschild gekennzeichnet. Hier ein Überblick:",
    high_requirements:
      "den bereits hohen europäischen Datenschutzstandard der DS-GVO hinaus",
    firstSectionItems: [
      {
        title: "Volle Kontrolle",
        text: "Habe jederzeit volle Kontrolle über deine Daten durch unser {emphasized}, in dem du deine Einwilligungen verwalten kannst und über zentral zusammengestellte Links zur Geltendmachung deiner Rechte kommst.",
        emphasized: "Datenschutz-Cockpit",
      },
      {
        title: "Kein Verkauf deiner Daten",
        text: "Wir {emphasized} Wenn wir dir Werbung anzeigen, erhalten unsere Werbepartner dabei keine Informationen über dich, sondern wir kuratieren selbst (z.B. anhand des geöffneten Gesetzbuchs) auf unseren eigenen, europäischen Servern.",
        emphasized: "verkaufen deine Daten nie.",
      },
      {
        title: "Nur europäische Server",
        text: "Die von dir eingegebenen Daten werden von uns, auch wenn dies mit Mehrkosten für uns verbunden ist, bei Hetzner Online auf europäischen Servern in Nürnberg gehostet. Auch bei der Auswahl unserer Integrationen (etwa für IT-Sicherheit oder Analyse) haben wir uns ausschließlich für europäische Serverstandorte entschieden.",
      },
      {
        title: "Kein Google Analytics oder Facebook-Pixel",
        text: "Wir verzichten auf die Nutzung großer US-Dienste wie Google Analytics oder des Facebook-Pixels. Um unser Angebot für dich dennoch verbessern zu können, verwenden wir stattdessen datenschutzfreundliche, {emphasized}.",
        emphasized: "auf europäischen Servern gehostete Open-Source-Tools",
      },
      {
        title: "Mehr Rechte",
        text: "Wir {emphasized} bei der Geltendmachung deiner Rechte (z.B. Widerspruchsrecht) und achten deine Entscheidung in jedem Fall, ohne rechtliche Schlupflöcher zu nutzen (z.B. löschen wir deine Daten unverzüglich nach Widerruf deiner Einwilligung und Berufen uns nicht auf sonstige Rechtfertigungsgründe des Art. 6 DS-GVO).",
        emphasized: "verzichten auf bürokratische Begründungspflichten",
      },
    ],
  },
};
