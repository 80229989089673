export const data_protection = {
  title: "Data Protection Information",
  subtitle: "GDPR and more!",
  intro_container: {
    title: "The Essentials in Brief",
    first_paragraph:
      "Even if you can't see it: At LexMea, the {trustworthy_policy} that arises from your use of our services is just as much a part of the service as a high-quality online offering itself.",
    trustworthy_policy:
      "secure, trustworthy, and respectful processing of data",
    second_paragraph:
      "To adequately protect the security of your data during transmission, we use methods based on the {current_technology} (e.g., DDoS protection, SQL injection protection, anti-CSRF tokens…).",
    current_technology:
      "current state-of-the-art encryption methods (e.g., SSL/TLS) and secure technical systems",
    third_paragraph:
      "To guarantee the protection of your personal data, we also go beyond {high_requirements} in many areas. You can find these areas marked by our LexMea data protection shield. Here is an overview:",
    high_requirements:
      "the already high European data protection standard of the GDPR",
    firstSectionItems: [
      {
        title: "Full Control",
        text: "Always have full control over your data through our {emphasized}, where you can manage your consents and access centrally compiled links to exercise your rights.",
        emphasized: "Data Protection Cockpit",
      },
      {
        title: "No Sale of Your Data",
        text: "We {emphasized} When we show you advertisements, our advertising partners do not receive any information about you, but we curate it ourselves (e.g., based on the opened law book) on our own European servers.",
        emphasized: "never sell your data.",
      },
      {
        title: "Only European Servers",
        text: "The data you enter is hosted by us, even if this involves additional costs for us, at Hetzner Online on European servers in Nuremberg. We have also chosen exclusively European server locations for our integrations (e.g., for IT security or analytics).",
      },
      {
        title: "No Google Analytics or Facebook Pixel",
        text: "We do not use major US services like Google Analytics or the Facebook Pixel. To still improve our offering for you, we instead use privacy-friendly, {emphasized}.",
        emphasized: "open-source tools hosted on European servers",
      },
      {
        title: "More Rights",
        text: "We {emphasized} when exercising your rights (e.g., right to object) and respect your decision in any case, without using legal loopholes (e.g., we delete your data immediately after revoking your consent and do not rely on other justifications under Art. 6 GDPR).",
        emphasized: "waive bureaucratic justification obligations",
      },
    ],
  },
};
