import { intro_page } from "~/i18n/de/infoPages/introPage";
import { vision_page } from "~/i18n/de/infoPages/visionPage";
import { team_mode } from "~/i18n/de/teamMode";
import { team_page } from "~/i18n/de/infoPages/teamMembers";
import { faq_page } from "~/i18n/de/infoPages/faq";
import { data_protection as data_privacy } from "~/i18n/de/infoPages/dataProtection";
import { imprint } from "~/i18n/de/infoPages/imprint";
import { terms_and_conditions } from "~/i18n/de/infoPages/termsAndConditions";
import { feedback_form } from "~/i18n/de/feedback_form";
import { onboardingTour } from "~/i18n/de/onboardingTour";
import { cookies } from "~/i18n/de/cookies";
import { ui } from "~/i18n/de/ui";
import { authentication } from "~/i18n/de/authentication";
import { entries } from "~/i18n/de/entries";
import { bookshelf } from "~/i18n/de/bookshelf";
import { legal_text } from "~/i18n/de/legalText";
import { settings } from "~/i18n/de/settings";
import { colors } from "~/i18n/de/colors";
import { redirects } from "~/i18n/de/redirects";

const de = {
  authentication,
  ui,
  settings,
  library: "Bibliothek",
  workspace: "Arbeitsbereich",
  info_site: "Info-Seiten",
  misc: "Sonstiges",
  tour: "Tour",
  intro: "Intro",
  vision: "Vision",
  team: "Team",
  faq: "F.A.Q.",
  terms_and_conditions: "AGB",
  data_protection: "Datenschutz",
  imprint: "Impressum",
  schemata: "Schemata",
  feedback: "Feedback",
  offline: "Du bist offline",
  eraser: "Radierer",
  eraser_help_text: "Löschen: Auf Personalisierung klicken.",
  updates: "Updates",
  subscribe: "Abonnieren",
  cancel: "Abbrechen",
  apply_changes: "Änderungen übernehmen",
  main_search_placeholder: "Paragraph, Gesetzbuch oder Stichwort suchen",
  bookshelf,
  feedback_form,
  entries,
  team_mode,
  cookies,
  legal_text,
  onboardingTour,
  info_pages: {
    intro_page,
    vision_page,
    team_page,
    faq_page,
    terms_and_conditions,
    data_privacy,
    imprint,
  },
  colors,
  redirects,
};

export default de;
